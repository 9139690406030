import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {}

// const ComponentToRender: React.FC<PrivateRouteProps>  = ({ component }) => <>{component} </>

const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...rest }) => {    
    const isAuthenticated = localStorage.getItem('inbrAdminToken');

    if (!isAuthenticated) return <Redirect to='/' />
  
    return <Route {...rest} />
}

export default PrivateRoute;