import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AdminProps } from '../../components/AdminTableRow';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';

interface UseParamsProps {
    id: string
}

export interface UpdateAdminProps {
    username: string,
    email: string,
    role: string,
    firstName: string,
    lastName: string,
    password: string,
    retypePassword: string,
}

const Admin: React.FC = () => {
    let token = localStorage.getItem("inbrAdminToken");

    if (token) {
        token = JSON.parse(token);
    }

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [admin, setAdmin] = useState<AdminProps>({
        id: "",
        username: "",
        email: "",
        status: 0,
        role: "",
        firstName: "",
        lastName: "",
        createdAt: "",
        updatedAt: "",
    });
    const [error, setError] = useState("");
    const { id } = useParams<UseParamsProps>();
    
    const [show, setShow] = useState(false);
    const [updatingAdmin, setUpdatingAdmin] = useState(false);
    const [adminState, setAdminState] = useState<UpdateAdminProps>({
        username: "",
        email: "",
        role: "",
        firstName: "",
        lastName: "",
        password: "",
        retypePassword: ""
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function getAdmin () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admins/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            if (result.error) {
                setError(result.error);
            } else {
                setAdmin(result.admin);
                setAdminState({
                    username: result.admin.username,
                    email: result.admin.email,
                    role: result.admin.role,
                    firstName: result.admin.firstName,
                    lastName: result.admin.lastName,
                    password: "",
                    retypePassword: ""
                });
            }
			// console.log(result);
		})
		.catch(error => {
            console.log(error);
			setError("Please check your connection");
			setLoading(false);
		});
	}
    
    const handleUpdateAdmin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (adminState.role === "") {
            Swal.fire({
                icon: "info",
                title: "Please select an admin role"
            });
        } else if (adminState.password !== adminState.retypePassword) {
            Swal.fire({
                icon: "error",
                title: "Password mismatch, check the passwords and try again"
            });
        } else {
            // console.log(adminState);
            updateAdmin();
        }
    }
    
    function updateAdmin () : void {
		setUpdatingAdmin(true);

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

        const sentBody = JSON.stringify({
            username: adminState.username,
            email: adminState.email,
            role: adminState.role,
            firstName: adminState.firstName,
            lastName: adminState.lastName,
            password: adminState.password
        });

        // console.log(sentBody, "sent body");

		let requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
            body: sentBody
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admins/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setUpdatingAdmin(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => {
                    setLoading(true);
                    getAdmin();
                    setAdminState({
                        username: "",
                        email: "",
                        role: "",
                        firstName: "",
                        lastName: "",
                        password: "",
                        retypePassword: ""
                    })
                    handleClose();
                });
            } else if (result.error) {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            }
		})
		.catch(error => {
			setError("Check your internet connection");
            // console.log(error)
			setUpdatingAdmin(false);
		});
	}

    function deleteAdmin () : void {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admins/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => history.push('/admins'));
            }
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            })
			setLoading(false);
		});
	}

    useEffect(() => {
        getAdmin();
        // eslint-disable-next-line
    }, []);

    const handleAdminFormInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setAdminState({
            ...adminState,
            [name]: value,
        });
	};

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    // useEffect(() => console.log(adminState, "adminState"), [adminState]);

    if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
        return <NotAuthorized />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}

    return (
        <div>
            <div>
                <NavLink to="/admins" className="custom-link">&larr; Back to all admins</NavLink>
            </div>
            <div className="card mb-3 mt-3">
				<div className="card-body p-3 d-flex justify-content-between" id="searchBody">
                    <button className="btn btn-info btn-md btn-block p-2 admin-edit-del-btn" onClick={handleShow}>
                        Edit
                    </button>
                    <button className="btn btn-danger btn-md btn-block p-2 mt-0 admin-edit-del-btn" onClick={deleteAdmin}>
                        Delete
                    </button>
				</div>
			</div>
            <div className="grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-row justify-content-between border-bottom">
                            <h4 className="card-title mb-1" style={{ textTransform: "uppercase", textAlign: "center" }}>{admin.role} ADMIN TYPE</h4>
                        </div> 
                        <div className="row">
                            <div className="col-12">
                                <div className="preview-list d-flex" style={{ flexDirection: "column" }}>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Username: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{admin.username}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Email: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{admin.email}</p> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Status: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{admin.status}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Role: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{admin.role}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">First Name: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{admin.firstName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Last Name: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{admin.lastName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Created At: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{parseISOString(admin.createdAt)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="admin-li-first-span">Updated At: </h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p>{parseISOString(admin.updatedAt)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>UPDATE ADMIN</Modal.Title>
                </Modal.Header>
                {
                    updatingAdmin ? (
                        <Roller />
                    ) : (
                        <Modal.Body>
                            <form className="form-horizontal" onSubmit={handleUpdateAdmin}>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        Username
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="userName" placeholder="Username" name="username" value={adminState.username} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="email" className="col-sm-2 col-form-label">
                                        Email
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="email" className="form-control edit-template-form-control" required id="email" placeholder="Email" name="email" value={adminState.email} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="role" className="col-sm-2 col-form-label">
                                        Role
                                    </label>
                                    <div className="col-sm-10">
                                        <select name="role" className="form-control edit-template-form-control" value={adminState.role} required onChange={handleAdminFormInputChange}>
                                            <option value="">Please select an admin role</option>
                                            <option value="owner">Owner</option>
                                            <option value="editor">Editor</option>
                                            <option value="viewer">Viewer</option>
                                            <option value="designer">Designer</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="firstName" className="col-sm-2 col-form-label">
                                        First Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="firstName" placeholder="First Name" name="firstName" value={adminState.firstName} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="lastName" className="col-sm-2 col-form-label">
                                        Last Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="lastName" placeholder="Last Name" name="lastName" value={adminState.lastName} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        New Password (OPTIONAL)
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control edit-template-form-control" id="password" placeholder="New Password" name="password" value={adminState.password} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        Retype Password (OPTIONAL)
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control edit-template-form-control" id="retypePassword" placeholder="Re Type Password" name="retypePassword" value={adminState.retypePassword} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-success p-2.5">Update Admin</button>
                            </form>
                        </Modal.Body>
                    )
                }
            </Modal>
        </div>
    )
}

export default Admin
