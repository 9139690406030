import React from 'react'

export const Roller: React.FC = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh"}}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Roller
