import React, { createContext, useState } from 'react'

interface AdminProfile {
    id: string,
    username: string,
    email: string,
    status: number,
    role: string,
    firstName: string,
    lastName: string,
    createdAt: string,
    updatedAt: string,
    meta: any
}

interface AdminAuth {
    adminProfile: AdminProfile | null,
    setAdminProfile: React.Dispatch<React.SetStateAction<AdminProfile | null>>
}

export const AuthContext = createContext<AdminAuth | null>(null);

const AuthProvider: React.FC = ({ children }) => {
    const [adminProfile, setAdminProfile] = useState<AdminProfile | null>(null);
    const auth: AdminAuth = {
        adminProfile,
        setAdminProfile,
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export default AuthProvider;