import React from 'react';
import { NavLink } from 'react-router-dom';
import { CategoryProps } from '../admin-pages/categories/Categories';
import '../admin-pages/categories/Categories.scss';

const CategoryComponent : React.FC<CategoryProps> = ({ coverImage, createdAt, name, description, id }) => {
    let splitArr;

    if (name.includes("&amp;")) {
        splitArr = name.split("&amp;");
    }

    return (
        <div className="card category-component">
            <div className="category-component-img-div">
                <img className="card-img-top" src={coverImage} alt="category" />
            </div>
            <div className="card-body category-body">
                <h5 className="card-title">{name.includes("&amp;") ? (<span>{splitArr?.[0]} &amp; {splitArr?.[1]}</span>) : name}</h5>
                <p className="card-text">{description}</p>
                <NavLink to={`/category/${id}`} className="btn btn-primary">View {name.includes("&amp;") ? (<span>{splitArr?.[0]} &amp; {splitArr?.[1]}</span>) : name}</NavLink>
            </div>
        </div>
    )
}

export default CategoryComponent
