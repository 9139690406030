import React, { useEffect, useState } from "react";
import { /* Link, */ useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import logo from "../../assets/images/logo/logo-horizontal.png";
import { Spinner } from "../shared/Spinner";
import useAuth from "../auth/useAuth";

const Login: React.FC = () => {
	const [loginDetails, setLoginDetails] = useState({
		username: "",
		password: ""
	});

	const history = useHistory();

	useEffect(() => {
		const authenticated = localStorage.getItem('inbrAdminToken');
		if (authenticated) {
			history.push('/dashboard');
		}
	}, [history])

	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState<string>("");

	const auth = useAuth();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setLoginDetails({
			...loginDetails,
			[name]: value
		})
	}

	const loginAdmin = async () => {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		let raw = JSON.stringify({
			username: loginDetails.username,
			password: loginDetails.password,
		});
	
		let requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
		};
	
		try {
			const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/auth/login`, requestOptions);
	
			if (response.status === 404) {
				setFetching(false);
				console.log(response, "404");
				setError(response.statusText);
				return response;
			} else if (response.status !== 200) {
				const error = await response.json();
				setFetching(false)
				console.log(error, "error");
				setError(error.error);
				return error;
			} else {
				const result = await response.json();
				setFetching(false)				
				console.log(result, "success");
				localStorage.setItem("inbrAdminToken", JSON.stringify(result.token.token));
				localStorage.setItem("adminProfile", JSON.stringify(result.admin))
				auth?.setAdminProfile(result.admin);
				history.push('/dashboard');
				return result;
			}
		} catch (error) {
			console.log(error, "the error");
			setFetching(false)
			return {
				status: "myCustomErr",
				error: "Your Connection Timed Out",
			};
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setFetching(true);
		loginAdmin();

		// password: "123456"
		// username: "pelumi"

		// console.log(loginDetails, "submitted");
	}

	// useEffect(() => console.log(loginDetails), [loginDetails]);

	return (
		<div>
			<div className="d-flex align-items-center auth px-0">
				<div className="row w-100 mx-0">
					<div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 mx-auto">
						<div className="card text-left py-5 px-4 px-sm-5">
							<div className="brand-logo">
								<img src={logo} alt="logo" />
							</div>
							<h4>Hello! let's get started</h4>
							<h6 className="font-weight-light">Sign in to continue.</h6>
							<Form className="pt-3" onSubmit={handleSubmit}>
								<Form.Group className="d-flex search-field">
									<Form.Control type="text" placeholder="Username" name="username" required value={loginDetails.username} onChange={handleChange} size="lg" className="h-auto" />
								</Form.Group>
								<Form.Group className="d-flex search-field">
									<Form.Control type="password" placeholder="Password" name="password" required value={loginDetails.password} onChange={handleChange} size="lg" className="h-auto" />
								</Form.Group>
								<div className="mt-3" style={{display: "flex", justifyContent: "center"}}>
									{
										fetching ? 
											<Spinner/> : 
										<button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Log in</button>
									}
								</div>
								<div className={`text-danger`}>{error}</div>
								{/* <div className="my-2 d-flex justify-content-between align-items-center">
									<div className="form-check">
										<label className="form-check-label text-muted">
											<input type="checkbox" className="form-check-input" />
											<i className="input-helper"></i>
											Keep me signed in
										</label>
									</div>
								</div> */}
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
