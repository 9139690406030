import React from 'react'

const NotAuthorized: React.FC = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "#000000"}}>
			Sorry you are not authorized to view this page
		</div>
    )
}

export default NotAuthorized
