import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import AdminTableRow, { AdminProps } from '../../components/AdminTableRow';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
export interface NewAdminData {
    username: string,
    email: string,
    role: string,
    password: string,
    firstName: string,
    lastName: string,
}

const Admins : React.FC = () => {
    const auth = useAuth();
    let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();

    if (token) {
        token = JSON.parse(token);
    }

    useEffect(() => {
        if (auth?.adminProfile?.role === "designer" || auth?.adminProfile?.role === "editor" || auth?.adminProfile?.role === "viewer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

    const [loading, setLoading] = useState(true);
    const [notAuthorized, setNotAuthorized] = useState(false);
    const [error, setError] = useState("");
    const [admins, setAdmins] = useState<[]>([]);

    const [show, setShow] = useState(false);
    const [creatingAdmin, setCreatingAdmin] = useState(false);
    const [adminState, setAdminState] = useState<NewAdminData>({
        username: "",
        email: "",
        role: "",
        password: "",
        firstName: "",
        lastName: "",
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function getAdmins () : void {
        setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admins`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            setAdmins(result.admins);
			// console.log(result);
		})
		.catch(error => {
            console.log(error);
			setError("Error please check your internet connection");
			setLoading(false);
		});
	}

    function createAdmin () : void {
		setCreatingAdmin(true);

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

        const sentBody = JSON.stringify({
            username: adminState.username,
            email: adminState.email,
            role: adminState.role,
            password: adminState.password,
            firstName: adminState.firstName,
            lastName: adminState.lastName,
        });

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
            body: sentBody
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admins`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setCreatingAdmin(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => {
                    setLoading(true);
                    getAdmins();
                    setAdminState({
                        username: "",
                        email: "",
                        role: "",
                        password: "",
                        firstName: "",
                        lastName: "",
                    })
                    handleClose();
                });
            } else if (result.error) {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            }
		})
		.catch(error => {
			setError("Check your internet connection");
            console.log(error)
			setCreatingAdmin(false);
		});
	}

    const handleAdminFormInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setAdminState({
            ...adminState,
            [name]: value,
        });
	};

    // useEffect(() => console.log(adminState, "adminState"), [adminState]);

    const handleCreateAdmin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (adminState.role === "") {
            Swal.fire({
                icon: "info",
                title: "Please select an admin role"
            });
        } else {
            // console.log(adminState);
            createAdmin();
        }
    }

    // useEffect(() => {
    //     if (auth?.adminProfile?.role === "owner") {
    //         getAdmins();
    //         setNotAuthorized(false);
    //     } else if (auth?.adminProfile === null) {
    //         setLoading(true);
    //     } else {
    //         setNotAuthorized(true);
    //         setLoading(false);
    //     }        
    //     // eslint-disable-next-line
    // }, [auth?.adminProfile]);
    useEffect(() => {
        getAdmins();
        setNotAuthorized(false);
        
        return () => setAdmins([]);
        // eslint-disable-next-line
    }, []);

    if (loading) {
		return <Roller />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	} else if (notAuthorized) {
        return <NotAuthorized />
    }

    return (
        <div>
            <div>
                <button type="button" className="btn btn-success btn-md" onClick={handleShow}>Create new admin</button>
            </div>
             <div className="card-body no-padding">
                <div className="table-wrap table-responsive card">
                    <table className="table m-0 table-hover">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Role</th> 
                                <th>Status</th> 
                                <th>Created At</th>
                                <th>Updated At</th>                      
                            </tr>
                        </thead>
                        <tbody>
                            {
                                admins.length === 0 ? (
                                    <tr>
                                        <td colSpan={8} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    admins.map((admin : AdminProps ) => <AdminTableRow key={admin.id} {...admin} />)
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>CREATE NEW ADMIN</Modal.Title>
                </Modal.Header>
                {
                    creatingAdmin ? (
                        <Roller />
                    ) : (
                        <Modal.Body>
                            <form className="form-horizontal" onSubmit={handleCreateAdmin}>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        Username
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="userName" placeholder="Username" name="username" value={adminState.username} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="email" className="col-sm-2 col-form-label">
                                        Email
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="email" className="form-control edit-template-form-control" required id="email" placeholder="Email" name="email" value={adminState.email} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="role" className="col-sm-2 col-form-label">
                                        Role
                                    </label>
                                    <div className="col-sm-10">
                                        <select name="role" className="form-control edit-template-form-control" value={adminState.role} required onChange={handleAdminFormInputChange}>
                                            <option value="">Please select an admin role</option>
                                            <option value="owner">Owner</option>
                                            <option value="editor">Editor</option>
                                            <option value="viewer">Viewer</option>
                                            <option value="designer">Designer</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="firstName" className="col-sm-2 col-form-label">
                                        First Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="firstName" placeholder="First Name" name="firstName" value={adminState.firstName} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="lastName" className="col-sm-2 col-form-label">
                                        Last Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="lastName" placeholder="Last Name" name="lastName" value={adminState.lastName} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="password" className="col-sm-2 col-form-label">
                                        Password
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control edit-template-form-control" required id="password" placeholder="Password" name="password" value={adminState.password} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-success p-2.5">Create Admin</button>
                            </form>
                        </Modal.Body>
                    )
                }
            </Modal>
        </div>
    )
}

export default Admins
