import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../auth/useAuth";
import AnalyticsCard from "../components/AnalyticsCard";
import NotAuthorized from "../shared/NotAuthorized";
import './Dashboard.scss';
export interface AnalyticsParams {
	dataFrom: string,
	dataTo: string,
}
export interface AnalyticsCardProps {
	type: string,
	fetching: boolean,
	error: string,
	data: AnalyticsObject,
	custom?: AnalyticsObject
}
interface GeneralAnalyticsObject {
	allTime: AnalyticsObject,
	last7Days: AnalyticsObject,
	last30Days: AnalyticsObject,
	last3Months: AnalyticsObject,
	custom?: AnalyticsObject
}
interface AnalyticsObject {
	campaignClicks: number,
	campaignViews: number,
	dataFrom: string,
	dataTo: string,
	emailSent: number,
	estimatedRevenue: number,
	generatedImages: number,
	newUsers: number,
	noOverageBillingCycles: number,
	paidBillingCycles: number,
	paidRevenue: number,
	publishedCampaigns: number,
	unpaidBillingCycles: number,
	unpaidRevenue: number,
	withOverageBillingCycles: number,
}

const Dashboard = () =>  {
    const auth = useAuth();
	const history = useHistory();
	
	let token = localStorage.getItem("inbrAdminToken");
	if (token) {
		token = JSON.parse(token);
	}

	const [analyticsParam, setAnalyticsParam] = useState<AnalyticsParams>({
		dataFrom: "",
		dataTo: ""
	});

	const handleAnalyticsParamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setAnalyticsParam({
			...analyticsParam,
			[name]: value
		})
	}

	const getXDaysAgo = (x: number) :object => {
		let ourDate = new Date();
		//Change it so that it is 7 days in the past.
		let pastDate = ourDate.getDate() - x;
		ourDate.setDate(pastDate);

		//Log the date to our web console.
		// console.log(ourDate);
		return ourDate;
	}

	const defaultAnalyticsObject = {
		campaignClicks: -1,
		campaignViews: -1,
		dataFrom: "",
		dataTo: "",
		emailSent: -1,
		estimatedRevenue: -1,
		generatedImages: -1,
		newUsers: -1,
		noOverageBillingCycles: -1,
		paidBillingCycles: -1,
		paidRevenue: -1,
		publishedCampaigns: -1,
		unpaidBillingCycles: -1,
		unpaidRevenue: -1,
		withOverageBillingCycles: -1,
	};

	const [analyticsAllTime, setAnalyticsAllTime] = useState<AnalyticsObject>(defaultAnalyticsObject);
	const [analyticsLast7Days, setAnalyticsLast7Days] = useState<AnalyticsObject>(defaultAnalyticsObject);
	const [analyticsLast30Days, setAnalyticsLast30Days] = useState<AnalyticsObject>(defaultAnalyticsObject);
	const [analyticsLast3Months, setAnalyticsLast3Months] = useState<AnalyticsObject>(defaultAnalyticsObject);
	const [analyticsCustomDate, setAnalyticsCustomDate] = useState<AnalyticsObject>(defaultAnalyticsObject);

	const [analyticsObject, setAnalyticsObject] = useState<GeneralAnalyticsObject>({
		allTime: defaultAnalyticsObject,
		last7Days: defaultAnalyticsObject,
		last30Days: defaultAnalyticsObject,
		last3Months: defaultAnalyticsObject,
	});

	useEffect(() => {
		if (analyticsAllTime.campaignClicks !== -1 && analyticsLast7Days.campaignClicks !== -1 && analyticsLast30Days.campaignClicks !== -1 && analyticsLast3Months.campaignClicks !== -1) {
			setAnalyticsObject({
				...analyticsObject,
				allTime: analyticsAllTime, 
				last7Days: analyticsLast7Days,
				last30Days: analyticsLast30Days,
				last3Months: analyticsLast3Months
			});			
		}

		return () => {
			setAnalyticsObject({
				allTime: defaultAnalyticsObject,
				last7Days: defaultAnalyticsObject,
				last30Days: defaultAnalyticsObject,
				last3Months: defaultAnalyticsObject,
			});
		}

		// eslint-disable-next-line
	}, [ analyticsAllTime.campaignClicks, analyticsLast7Days.campaignClicks, analyticsLast30Days.campaignClicks, analyticsLast3Months.campaignClicks ]);

	// useEffect(() => console.log(analyticsObject, "analytics object"), [analyticsObject]);

    const [notAuthorized, setNotAuthorized] = useState(false);

	const [fetchingAllTime, setFetchingAllTime] = useState<boolean>(false);
	const [errorFetching, setErrorFetching] = useState<string>("");
	const [fetchingLast7Days, setFetchingLast7Days] = useState<boolean>(false);
	const [fetchingLast30Days, setFetchingLast30Days] = useState<boolean>(false);
	const [fetchingLast3Months, setFetchingLast3Months] = useState<boolean>(false);
	const [fetchingCustomDate, setFetchingCustomDate] = useState<boolean>(false);
	const [generalFetching, setGeneralFetching] = useState<boolean>(false);
	const [customSelection, setCustomSelection] = useState<boolean>(false);

	function getAnalytics (range: string, dataFrom?: object, dataTo?: object) : void {
		// console.log("starting");
		// console.log(dataFrom, dataTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};
		// console.log(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/analytics?dataFrom=${dataFrom}&dataTo=${dataTo}`)
		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/analytics?dataFrom=${dataFrom}&dataTo=${dataTo}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			// console.log(result, range);
			if (range === "All time") {
				setAnalyticsAllTime(result);
				setFetchingAllTime(false);
			} else if (range === "Last 7 days") {
				setAnalyticsLast7Days(result);
				setFetchingLast7Days(false);
			} else if (range === "Last 30 days") {
				setAnalyticsLast30Days(result);
				setFetchingLast30Days(false);
			} else if (range === "Last 3 months") {
				setAnalyticsLast3Months(result);
				setFetchingLast3Months(false);
			} else if (range === "Custom date") {
				setAnalyticsCustomDate(result);
				setFetchingCustomDate(false);
			}
		})
		.catch(error => {
			setErrorFetching("Type Error: Failed to fetch");
			console.log(error);
			// console.log(error.json(), "json");
			setGeneralFetching(false);
		});
	}

	useEffect(() => {
		if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
            setNotAuthorized(true);   
        } 

		// eslint-disable-next-line
	}, [auth?.adminProfile]);

	useEffect(() => {
		setNotAuthorized(false);
		setGeneralFetching(true);
		setFetchingAllTime(true);
		setFetchingLast7Days(true);
		setFetchingLast30Days(true);
		setFetchingLast3Months(true);
		setCustomSelection(false);
		getAnalytics("All time", new Date("2018-01-01"), new Date());
		getAnalytics("Last 7 days", getXDaysAgo(7), new Date());
		getAnalytics("Last 30 days", getXDaysAgo(30), new Date());
		getAnalytics("Last 3 months", getXDaysAgo(91.2501), new Date()); 

		return () => {
			setAnalyticsAllTime(defaultAnalyticsObject);
			setAnalyticsLast7Days(defaultAnalyticsObject);
			setAnalyticsLast30Days(defaultAnalyticsObject);
			setAnalyticsLast3Months(defaultAnalyticsObject);
			setAnalyticsCustomDate(defaultAnalyticsObject);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (generalFetching) {
			if (!fetchingAllTime && !fetchingLast7Days && !fetchingLast30Days && !fetchingLast3Months && !fetchingCustomDate) {
				setGeneralFetching(false);
			}
		}
	}, [fetchingAllTime, fetchingLast7Days, fetchingLast30Days, fetchingLast3Months, fetchingCustomDate, generalFetching]);

	const handleCustomDateSelection = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setGeneralFetching(true);
		setFetchingCustomDate(true);
		setCustomSelection(true);
		// console.log(analyticsParam.dataFrom);
		// console.log(new Date(analyticsParam.dataFrom))

		getAnalytics("Custom date", new Date(analyticsParam.dataFrom), new Date(analyticsParam.dataTo));
	}

	const resetBackToDefault = () => {
		setAnalyticsCustomDate(defaultAnalyticsObject);
		setCustomSelection(false);
		setAnalyticsParam({
			dataFrom: "",
			dataTo: ""
		})
	}

	// useEffect(() => console.log(analyticsParam), [analyticsParam]);
	// useEffect(() => console.log(generalFetching, "general fetchiing"), [generalFetching]);

	if (notAuthorized) {
        return <NotAuthorized />
    }
	
	return (
		<div>
			<div className="">
				<form onSubmit={handleCustomDateSelection} className="custom-date-range-form">
					<h4>Custom Date Range Selection</h4>
					<div className="input-div">
						<label htmlFor="dataFrom">Date From</label>
						<input type="date" className="form-control" name="dataFrom" id="dataFrom" required value={analyticsParam.dataFrom} onChange={handleAnalyticsParamChange} />
					</div>
					<div className="input-div">
						<label htmlFor="dataTo">Date To</label>
						<input type="date" className="form-control" name="dataTo" id="dataTo" required value={analyticsParam.dataTo} onChange={handleAnalyticsParamChange} />
					</div>
					<div className="d-flex justify-content-between custom-btn-div">
						<button className="btn btn-dark btn-md font-weight-medium" type="submit">Use Custom Date</button>
						<button className="btn btn-dark btn-md font-weight-medium" type="button" onClick={resetBackToDefault}>Reset Back To Default</button>
					</div>
				</form>
				<div className="row">
					{
						customSelection ? (
							<AnalyticsCard type="Custom" fetching={generalFetching} error={errorFetching} data={analyticsObject.last3Months} custom={analyticsCustomDate} /> 
						) : (
							<>
								<AnalyticsCard type="All Time" fetching={generalFetching} error={errorFetching} data={analyticsObject.allTime} />
								<AnalyticsCard type="Last 7 Days" fetching={generalFetching} error={errorFetching} data={analyticsObject.last7Days} />
								<AnalyticsCard type="Last 30 Days" fetching={generalFetching} error={errorFetching} data={analyticsObject.last30Days} />
								<AnalyticsCard type="Last 3 Months" fetching={generalFetching} error={errorFetching} data={analyticsObject.last3Months} />
							</>
						)
					}
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
