import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import UserTableRow, { UserTableRowProps } from '../../components/UserTableRow';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';

interface UserResults {
    query: string,	
    username: string,
    email: string,	
    page: string,	
    perPage: string,
    sortBy: string,	
    orderBy: string,
}

const Users : React.FC = () => {
    let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();

    if (token) {
        token = JSON.parse(token);
    }

    const auth = useAuth();

    useEffect(() => {
        if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [users, setUsers] = useState<[]>([]);
	const [userResults, setUserResults] = useState<UserResults>({
        query : "",
        username : "",
        email : "",
        page : "",
        perPage : "",
        sortBy : "",
        orderBy : "",
    });

    const [itemsPerPage, setItemsPerPage] = useState<number>(10);


    function getUsers (query: string = "", username: string = "", email: string = "", page: number = 1, perPage: number = itemsPerPage, sortBy: string = "", orderBy: string = "") : void {
		setLoading(true);
        let queryString: string = `${query}&username=${username}&email=${email}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&orderBy=${orderBy}`;

		// console.log("starting");
		// console.log(dataFrom, dataTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/users?query=${queryString}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            if (result.status === "ok") {
                setUsers(result.users);
                setUserResults(result.results);
            } else if (result.error === "Invalid Permission") {
                setError("Invalid Permission");
            } else if (result.error) {
                setError(result.error);
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            }
			// console.log(result);
		})
		.catch(error => {
            console.log(error);
			setError("Error please check your internet connection");
			setLoading(false);
		});
	}

    const [sortByState, setSortByState] = useState("");
	const [orderByState, setOrderByState] = useState("");

	const sortBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(e.target.value);

		if (e.target.value === "") {
			setSortByState("");
			getUsers(userResults.query, "", "", Number(currentPage) - 1, itemsPerPage, "", orderByState);
		} else {
			setSortByState(e.target.value);
			getUsers(userResults.query, "", "", Number(currentPage) - 1, itemsPerPage, e.target.value, orderByState);
		}
	};

	const orderBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(e.target.value);

		if (e.target.value === "") {
			setOrderByState("");
			getUsers(userResults.query, "", "", Number(currentPage) - 1, itemsPerPage, sortByState, "");
		} else {
			setOrderByState(e.target.value);
			getUsers(userResults.query, "", "", Number(currentPage) - 1, itemsPerPage, sortByState, e.target.value);
		}
	};

    let searchSeconds = 1000; // milliseconds for the search timeout
	const [nameSearchValue, setNameSearchValue] = useState("");

	useEffect(() => {
		let nameSearchId: NodeJS.Timeout;

		if (nameSearchValue) {
			nameSearchId = setTimeout(() => {
				setLoading(true);
				getUsers(nameSearchValue);
			}, searchSeconds);
		}

		return () => clearTimeout(nameSearchId);

		// eslint-disable-next-line
	}, [nameSearchValue, searchSeconds]);

	const [userNameSearchValue, setUserNameSearchValue] = useState("");
	useEffect(() => {
		let userNameSearchId: NodeJS.Timeout;

		if (userNameSearchValue) {
			userNameSearchId = setTimeout(() => {
				setLoading(true);
				getUsers("", userNameSearchValue, "");
			}, searchSeconds);
		}

		return () => clearTimeout(userNameSearchId);

		// eslint-disable-next-line
	}, [userNameSearchValue, searchSeconds]);

	const [emailSearchValue, setEmailSearchValue] = useState("");
	useEffect(() => {
		let emailSearchId: NodeJS.Timeout;

		if (emailSearchValue) {
			emailSearchId = setTimeout(() => {
				setLoading(true);
				getUsers("", "", emailSearchValue);
			}, searchSeconds);
		}

		return () => clearTimeout(emailSearchId);

		// eslint-disable-next-line
	}, [emailSearchValue, searchSeconds]);

    const [currentPage, setCurrentPage] = useState(1);

	const prev = () => {
		if (Number(userResults.page) === 1) {
			return;
		} else {
			getUsers(userResults.query, "", "", Number(currentPage) - 1, itemsPerPage, userResults.sortBy, userResults.orderBy);
			setCurrentPage(currentPage - 1);
			// console.log("previous");
		}
	};

	const next = () => {
		if (users.length !== 0) {
			setCurrentPage(Number(userResults.page) + 1);
			getUsers(userResults.query, "", "", Number(currentPage) + 1, itemsPerPage, userResults.sortBy, userResults.orderBy);
		} else {
			return;
		}
	};


    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, []);

    // useEffect(() => console.log(error), [error]);
    // useEffect(() => {
    //     console.log(itemsPerPage);
    // }, [itemsPerPage]);

    useEffect(() => {
		setNameSearchValue(userResults?.query);
        setOrderByState(userResults?.orderBy);
        setSortByState(userResults?.sortBy);
	}, [userResults])

    if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	} 

    return (
        <div className="card">
            <div className="card-header">
                <div className="card mb-1">
                    <div className="card-body p-3 d-flex justify-content-between" id="searchBody">
                        <div className="form-group m-0 template-search-div">
                            <label>General search</label>                            
                            <input type="text" className="form-control" id="searchQuery" placeholder="General search" 
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setNameSearchValue("");
                                        setTimeout(() => {
                                            getUsers();
                                        }, 1000);
                                    } else {
                                        setNameSearchValue(e.target.value);
                                    }
                                }}
                                value={nameSearchValue} 
                            />
                        </div>
                        <div className="form-group m-0 template-search-div">
                            <label>Search by username</label>                            
                            <input type="text" className="form-control" id="searchQuery" placeholder="Search by username" 
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setUserNameSearchValue("");
                                        setTimeout(() => {
                                            getUsers();
                                        }, 1000);
                                    } else {
                                        setUserNameSearchValue(e.target.value);
                                    }
                                }}
                                value={userNameSearchValue} 
                            />
                        </div>
                        <div className="form-group m-0 template-search-div">
                            <label>Search by email</label>                            
                            <input type="text" className="form-control" id="searchEmail" placeholder="Search by email" 
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setEmailSearchValue("");
                                        setTimeout(() => {
                                            getUsers();
                                        }, 1000);
                                    } else {
                                        setEmailSearchValue(e.target.value);
                                    }
                                }}
                                value={emailSearchValue} 
                            />
                        </div>
                    </div>
                </div>
                <div className="card mb-1">
                    <div className="card-body p-3 d-flex justify-content-between" id="searchBody">                        
                        <div className="form-group m-0 template-search-div">
                            <label>Sorting</label>
                            <select className="form-control sort-select" onChange={sortBySelect} value={sortByState}>
                                <option defaultValue="">Sort by</option>
                                <option value="alphabetical">Alphabetical</option>
                                <option value="dateCreated">Date created</option>
                                <option value="lastModified">Last modified</option>
                            </select>
                        </div>
                        <div className="form-group m-0 template-search-div">
                            <label>Number of results to show per page</label>                            
                            <input type="text" className="form-control" 
                                onChange={(e) => {
                                    const enteredNo = Number(e.currentTarget.value);
                                    // console.log(typeof(enteredNo));
                                    // console.log(enteredNo, "enteredNo");
                                    if (isNaN(enteredNo)) {
                                        console.log("Please enter a number not an alphabet")
                                    } else {                                        
                                        setItemsPerPage(enteredNo);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    // console.log(e.key);
                                    if (e.key === "Enter") {
                                        console.log("perform the search now");
                                        getUsers();
                                    }
                                }}
                                value={itemsPerPage} 
                            />
                        </div>
                        <div className="form-group m-0 template-search-div">  
                            <label>Ordering</label>                          
                            <select className="form-control sort-select" onChange={orderBySelect} value={orderByState}>
                                <option defaultValue="">Order by</option>
                                <option value="ascending">Ascending</option>
                                <option value="descending">Descending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body no-padding">
                <div className="table-wrap table-responsive">
                    <table className="table m-0 table-hover">
                        <thead>
                            <tr>
                                <th>Profile Photo</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone Number</th>
                                <th>Email Verified</th>
                                <th>Account Activated</th>   
                                <th>Brand Name</th>
                                <th>Website Url</th> 
                                <th>Created At</th>
                                <th>Updated At</th>                      
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.length === 0 ? (
                                    <tr>
                                        <td colSpan={7} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    users.map((user : UserTableRowProps ) => <UserTableRow key={user.id} {...user} />)
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
			<div className="card">
				<div className="card-footer clearfix">
					<ul className="pagination pagination-sm m-0 float-right">
						<li className={`${Number(userResults.page) === 1 ? "dont-click" : "you-can-click"} page-item`}>
							<span className="page-link" onClick={prev}>
								« Previous
							</span>
						</li>
						<li className={`${users.length !== 0 ? "you-can-click" : "dont-click"} page-item`}>
							<span className="page-link" onClick={next}>
								Next »
							</span>
						</li>
					</ul>
				</div>
			</div>
        </div>
    )
}

export default Users
