import React, { useEffect, useState } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom';
import Roller from '../../shared/Roller';
import { CategoryProps } from './Categories';
import '../templates/Templates.scss';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';

interface UseParamsProps {
    id: string
}

interface UpdateCategoryData {
    name: string,
    description: string,
    slug: string,
    coverImage: string
}

const Category : React.FC = () => {
    const history = useHistory();
    let token = localStorage.getItem("inbrAdminToken");
    const auth = useAuth();

    if (token) {
        token = JSON.parse(token);
    }

    const { id } = useParams<UseParamsProps>();
    const [category, setCategory] = useState<CategoryProps>();
    const [loading, setLoading] = useState(true);
    const [updatingCategory, setUpdatingCategory] = useState(false);
    const [error, setError] = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function getCategory () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            setCategory(result.category);
            setCategoryState({
                name: result.category.name.includes("&amp;") ? result.category.name.replace("&amp;", "&") : result.category.name,
                description: result.category.description,
                slug: result.category.slug,
                coverImage: result.category.coverImage
            });
			// console.log(result);
		})
		.catch(error => {
			setError(error);
			setLoading(false);
		});
	}

    const [coverImageLoading, setCoverImageLoading] = useState(false);
    const [categoryState, setCategoryState] = useState<UpdateCategoryData>({
        name: "",
        description: "",
        slug: "",
        coverImage: ""
    });

    function uploadFile (file: any, name: any) : void {
		setCoverImageLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let formData = new FormData();
        // formdata.append("file", fileInput.files[0], "/C:/Users/Pelumi/Pictures/Animes/Kimetsu no Yaiba/2413000.jpg");
        formData.append("file", file, file.name);

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        };

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/uploads`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setCoverImageLoading(false);
            setCategoryState({
                ...categoryState,
                coverImage: result.upload.finalUrl
            })
			// console.log(result);
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            })
			setCoverImageLoading(false);
		});
	}

    const checkUploadSize = (fileUploadElement: any) => {
        // console.log(fileUploadElement?.files)
		if (typeof fileUploadElement?.files != "undefined") {
			// let size = parseFloat(fileUploadElement.files[0].size / 1024).toFixed(2);
			let size = parseFloat(fileUploadElement?.files[0].size);
			// console.log(size);
			if (size > 5242880 || size === 0) {
				Swal.fire({
					icon: "error",
					title: "File size is larger than 5MB please choose another image",
				});
				return false;
			} else {
				return true;
			}
		}
	};

    const handleCategoryFormInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		if ((e.target as HTMLInputElement).files && (e.target as HTMLInputElement).files?.length) {
            // console.log((e.target as HTMLInputElement).files)

			const answer = checkUploadSize((e.target as HTMLInputElement));
            if (answer) {
                setCoverImageLoading(!coverImageLoading);
                uploadFile((e.target as HTMLInputElement).files?.[0], name);
            }
		} else {
			setCategoryState({
				...categoryState,
				[name]: value,
			});
		}
	};

    function updateCategory () : void {
		setUpdatingCategory(true);

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

        const sentBody = JSON.stringify({
            name: categoryState.name,
            description: categoryState.description,
            slug: categoryState.slug,
            coverImage: categoryState.coverImage
        });

		let requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
            body: sentBody
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setUpdatingCategory(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => {
                    setLoading(true);
                    getCategory();
                    handleClose();
                });
            } else if (result.status === "error") {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            } else if (result.error === "Invalid Permission") {
                Swal.fire({
                    icon: "error",
                    title: "Sorry you are not authorized to perform this operation"
                }).then(() => handleClose());
			}
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            });
            console.log(error)
			setUpdatingCategory(false);
		});
	}

    function deleteCategory () : void {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => history.push('/categories'));
            } else if (result.status === "error") {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            } else if (result.error === "Invalid Permission") {
                Swal.fire({
                    icon: "error",
                    title: "Sorry you are not authorized to perform this operation"
                });
			}
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            })
			setUpdatingCategory(false);
		});
	}

    const handleUpdateCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (categoryState.coverImage === "") {
            Swal.fire({
                icon: "info",
                title: "Please upload a cover image before continuing"
            });
        } else {
            // console.log(categoryState);
            updateCategory();
        }
    }

    useEffect(() => {
        getCategory();
        
        // eslint-disable-next-line
    }, []);

    if (loading) {
		return <Roller />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}
    
    return (
        <div>
            <div>
                <NavLink to="/categories" className={` custom-link`}>&larr; Back to all categories</NavLink>
            </div>
            <div className="row mt-3 mb-5 pb-5">
                <div className="col-md-6">
                    {category?.coverImage ? (
                        <img src={category?.coverImage} className="preview_img" alt="category preview" />
                    ) : (
                        <div style={{
                            height: "100%",
                            border: "1px solid #ff0066",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}>NO PREVIEW IMAGE</div>
                    )}
                </div>
                <div className="col-md-6">
                    <h1>{category?.name.includes("&amp;") ? category?.name.replace("&amp;", "&") : category?.name}</h1>
                    <div className="template-btn-div">
                        <button className={`btn btn-info btn-md btn-block p-2 ${auth?.adminProfile?.role === "viewer" ? "not-allowed" : ""}`} disabled={auth?.adminProfile?.role === "viewer" ? true : false } onClick={handleShow}>
                            Edit
                        </button>
                        <button className={`btn btn-danger btn-md btn-block p-2 mt-0 ${auth?.adminProfile?.role === "viewer" ? "not-allowed" : ""}`} disabled={auth?.adminProfile?.role === "viewer" ? true : false } onClick={deleteCategory}>
                            Delete
                        </button>
                    </div>
                    <br />
                    <h2>About this category</h2>
                    <div>{category?.description}</div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>UPDATE CATEGORY</Modal.Title>
                </Modal.Header>
                {
                    updatingCategory ? (
                        <Roller />
                    ) : (
                        <Modal.Body>
                            <form className="form-horizontal" onSubmit={handleUpdateCategory}>
                                <div className="form-group row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label">
                                        Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="inputName" placeholder="Name" name="name" value={categoryState.name} onChange={handleCategoryFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="description" className="col-sm-2 col-form-label">
                                        Description
                                    </label>
                                    <div className="col-sm-10">
                                        <textarea className="form-control edit-template-form-control" id="description" required rows={5} placeholder="Short description about this list" name="description" value={categoryState.description} onChange={handleCategoryFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="slug" className="col-sm-2 col-form-label">
                                        Slug
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="slug" placeholder="Slug" name="slug" value={categoryState.slug} onChange={handleCategoryFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="coverImage" className="input-image-label">Cover image</label>
                                    <div className="d-flex align-items-start" style={{ marginLeft: "1%"}}>
                                        <div className="image-div">
                                            <img
                                                className="img-circle image"
                                                src={categoryState.coverImage ? categoryState.coverImage : "/assets/img/boxed-bg.jpg"}
                                                alt="cover"
                                            />
                                            {!coverImageLoading && !categoryState.coverImage ? (
                                                <span className="no-image-selected">No Profile Photo Uploaded</span>
                                            ) : coverImageLoading ? (
                                                <div className="lds-roller lds-roller-black" style={{display: "flex", position: "absolute", top: "20%", left: "20%"}}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="align-self-center">
                                            <label id="inputProfilePhotoLabel" className="btn btn-sm btn-default input-image-label choose-btn" htmlFor="coverImage">
                                                Choose cover image
                                            </label>
                                            <input
                                                type="file"
                                                accept=".png, .jpeg, .jpg"
                                                className="input-image"
                                                id="coverImage"
                                                onChange={handleCategoryFormInputChange}
                                                name="coverImage"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-success p-2.5">Update Category</button>
                            </form>
                        </Modal.Body>
                    )
                }
            </Modal>
        </div>
    )
}

export default Category
