import React from 'react';
import { useHistory } from 'react-router-dom';

export interface AdminProps {
    id: string,
    username: string,
    email: string,
    status: number,
    role: string,
    firstName: string,
    lastName: string,
    createdAt: string,
    updatedAt: string,
    meta?: any
}

const AdminTableRow : React.FC<AdminProps> = ({ username, email, firstName, lastName, role, status, createdAt, updatedAt, id }) => {
    const history = useHistory();

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    return (
        <tr style={{ cursor: "pointer" }} onClick={() => history.push(`/admin/${id}`)}>
            <td className="custom-text-capitalize">{username ?? "-"}</td>
            <td>{email ? email : "-"}</td>
            <td>{firstName ? firstName : "-"}</td>
            <td>{lastName ? lastName : "-"}</td>
            <td>{role ? role : "-"}</td>
            <td>{status ? status : "-"}</td>
            <td>{parseISOString(createdAt) ?? "-"}</td>
            <td>{parseISOString(updatedAt) ?? "-"}</td>
        </tr>
    )
}

export default AdminTableRow
