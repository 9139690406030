import React, { useEffect, useState } from "react";
import { /* withRouter */ useLocation } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
// import { withTranslation } from "react-i18next";

const App = () => {
	const [appState, setAppState] = useState({
		isFullPageLayout: true,
	})

	const location = useLocation();

	useEffect(() => {
		onRouteChanged();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// if the location has changed call this onRouteChanged again
		onRouteChanged();

		// eslint-disable-next-line
	}, [location])

	function onRouteChanged() {
		console.log("ROUTE CHANGED");
		// const { i18n } = this.props;
		// const body = document.querySelector("body");
		if (location.pathname === "/layout/RtlLayout") {
			document.body.classList.add("rtl");
			// i18n.changeLanguage('ar');
		} else {
			document.body.classList.remove("rtl");
			// i18n.changeLanguage('en');
		}
		window.scrollTo(0, 0);
		const fullPageLayoutRoutes = [
			"/",
			"/login",
			"/user-pages/register-1",
			"/user-pages/register-2",
			"/user-pages/lockscreen",
			"/error-pages/error-404",
			"/error-pages/error-500",
			"/general-pages/landing-page",
		];
		for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
			if (location.pathname === fullPageLayoutRoutes[i]) {
				setAppState({
					isFullPageLayout: true,
				});
				let pageBody = document.querySelector(".page-body-wrapper");
				pageBody?.classList.add("full-page-wrapper");
				break;
			} else {
				setAppState({
					isFullPageLayout: false,
				});
				let pageBody = document.querySelector(".page-body-wrapper");
				pageBody?.classList.remove("full-page-wrapper");
			}
		}
	}

	
	let navbarComponent = !appState.isFullPageLayout ? <Navbar /> : "";
	let sidebarComponent = !appState.isFullPageLayout ? <Sidebar /> : "";
	let footerComponent = !appState.isFullPageLayout ? <Footer /> : "";

	return (
		<div className="container-scroller">
			{sidebarComponent}
			<div className="container-fluid page-body-wrapper">
				{navbarComponent}
				<div className="main-panel">
					<div className="content-wrapper">
						<AppRoutes />
					</div>
					{footerComponent}
				</div>
			</div>
		</div>
	);
}

export default /* withTranslation()( */ /* withRouter( */App/*)*/ /* ) */;
