import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import Roller from '../../shared/Roller';

interface AdminProps {
    id: string,
    username: string,
    email: string,
    status: number,
    role: string,
    firstName: string,
    lastName: string,
    createdAt: string,
    updatedAt: string,
    meta: any
}
interface UpdateAdminProps {
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    retypePassword: string,
}

const Profile: React.FC = () => {
    let token = localStorage.getItem("inbrAdminToken");
    const auth = useAuth();

    if (token) {
        token = JSON.parse(token);
    }

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [admin, setAdmin] = useState<AdminProps>();
    const [show, setShow] = useState(false);
    const [updatingAdmin, setUpdatingAdmin] = useState(false);
    const [adminState, setAdminState] = useState<UpdateAdminProps>({
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        retypePassword: ""
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAdminFormInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setAdminState({
            ...adminState,
            [name]: value,
        });
	};

    function getAdminProfile () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/auth/user-info`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            setAdmin(result.admin);
			// console.log(result);
		})
		.catch(error => {
			setError("Please check your connection and try again");
            console.log(error);
			setLoading(false);
		});
	}

    function updateProfile () : void {
		setUpdatingAdmin(true);

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

        const sentBody = JSON.stringify({
            username: adminState.username,
            email: adminState.email,
            firstName: adminState.firstName,
            lastName: adminState.lastName,
            password: adminState.password
        });

        // console.log(sentBody, "sent body");

		let requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
            body: sentBody
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/auth/user-info`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setUpdatingAdmin(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => {
                    auth?.setAdminProfile({
                        ...auth.adminProfile,
                        username: adminState.username,
                        email: adminState.email,
                        role: auth.adminProfile?.role ?? "",
                        firstName: adminState.firstName,
                        lastName: adminState.lastName,
                        id: auth.adminProfile?.id ?? "",
                        status: auth.adminProfile?.status ?? 0,
                        createdAt: auth.adminProfile?.createdAt ?? "",
                        updatedAt: auth.adminProfile?.updatedAt ?? "",
                        meta: auth.adminProfile?.meta ?? ""
                    });
                    setLoading(true);
                    getAdminProfile();
                    handleClose();
                });
            } else if (result.error) {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            }
		})
		.catch(error => {
			setError("Check your internet connection");
            console.log(error)
			setUpdatingAdmin(false);
		});
	}

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    const handleUpdateProfile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (adminState.password !== adminState.retypePassword) {
            Swal.fire({
                icon: "error",
                title: "Password mismatch, check the passwords and try again"
            });
        } else {
            // console.log(adminState);
            updateProfile();
        }
    }
    
    useEffect(() => {
        // console.log(auth?.adminProfile);
        if (auth?.adminProfile?.id) {
            getAdminProfile();
            setAdminState({
                username: auth.adminProfile.username,
                lastName: auth.adminProfile.lastName,
                firstName: auth.adminProfile.firstName,
                email: auth.adminProfile.email,
                password: "",
                retypePassword: ""
            });            
            localStorage.setItem("adminProfile", JSON.stringify(auth?.adminProfile))
        }

        // eslint-disable-next-line
    }, [auth?.adminProfile]);

    // useEffect(() => {
    //     console.log(admin, "admin");
    // }, [admin])

    if (loading) {
		return <Roller />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}

    return (
        <div>
            <h1>YOUR PROFILE</h1>
            <div className="row mt-3 mb-5 pb-5">
                <div className="col-md-6">
                    <h3>{`${admin?.firstName} ${admin?.lastName}`}</h3>
                    <p>
                        Email: &nbsp;
                        <span>
                            <strong>{admin?.email ?? "No Email"}</strong>
                        </span>
                    </p>
                    <p>
                        Username: &nbsp;
                        <span>
                            <strong className={`text-capitalize`}>{admin?.username ?? "No Username"}</strong>
                        </span>
                    </p>
                    <p>
                        Role: &nbsp;
                        <span>
                            <strong className={`text-capitalize`}>{admin?.role ?? "Role"}</strong>
                        </span>
                    </p>
                    <p>
                        Created At: &nbsp;
                        <span>
                            <strong>{parseISOString(admin?.createdAt ?? "")}</strong>
                        </span>
                    </p>
                    <div className="template-btn-div">
                        <button className={`btn btn-info btn-md btn-block p-2`} onClick={handleShow}>
                            Update Profile
                        </button>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>UPDATE ADMIN</Modal.Title>
                </Modal.Header>
                {
                    updatingAdmin ? (
                        <Roller />
                    ) : (
                        <Modal.Body>
                            <form className="form-horizontal" onSubmit={handleUpdateProfile}>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        Username
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="userName" placeholder="Username" name="username" value={adminState.username} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="email" className="col-sm-2 col-form-label">
                                        Email
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="email" className="form-control edit-template-form-control" required id="email" placeholder="Email" name="email" value={adminState.email} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="firstName" className="col-sm-2 col-form-label">
                                        First Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="firstName" placeholder="First Name" name="firstName" value={adminState.firstName} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="lastName" className="col-sm-2 col-form-label">
                                        Last Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="lastName" placeholder="Last Name" name="lastName" value={adminState.lastName} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        New Password (OPTIONAL)
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control edit-template-form-control" id="password" placeholder="New Password" name="password" value={adminState.password} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="userName" className="col-sm-2 col-form-label">
                                        Retype Password (OPTIONAL)
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control edit-template-form-control" id="retypePassword" placeholder="Re Type Password" name="retypePassword" value={adminState.retypePassword} onChange={handleAdminFormInputChange} />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-success p-2.5">Update Profile</button>
                            </form>
                        </Modal.Body>
                    )
                }
            </Modal>
        </div>
    )
}

export default Profile
