import React from 'react'
import { NavLink } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import '../admin-pages/templates/Templates.scss';

export interface TemplateCategoryProps {
    name: string,
    slug: string,
    sortingFunction: (query: string, category: string, tag: string, page: number) => void
}

const TemplateCategory : React.FC<TemplateCategoryProps> = ({ name, slug, sortingFunction }) => {
    // let replaced;
    let splitArr;

    if (name.includes("&amp;")) {
        splitArr = name.split("&amp;");
    }

    return (
        <div className={`t-category ${(name.includes("&amp;") || name.length > 10 ) ? 'wide-category' : ""}`} onClick={() => sortingFunction("", slug, "", 1)}>
            <NavLink exact to={`#`} activeClassName="active" style={{textDecoration: "none"}}>
                <div className="wrap">
                    <h5 className="name text-ellipse">{name.includes("&amp;") ? (<span>{splitArr?.[0]} &amp; {splitArr?.[1]}</span>) : name}</h5>
                </div>
            </NavLink>
        </div>
    )
}

export default TemplateCategory
