import React from 'react';
import { useHistory } from "react-router-dom";

export interface UserTableRowProps {
    id: string,
    username: string,
    email: string,
    status: number,
    firstName: string,
    lastName: string,
    brandName: string,
    websiteUrl: string,
    phoneNumber: string,
    profilePhoto: string,
    coverPhoto: string,
    shortBio: string,
    bestDescribed: string,
    createdAt: string,
    updatedAt: string,
    accountActivated: boolean,
    emailVerified: boolean,
    socialLinks: any,
    address: {
        city: string,
        line1: string,
        line2: string,
        state: string,
        country: string,
        zipCode: string
    },
    meta: any
}

const UserTableRow : React.FC<UserTableRowProps> = (userData) => {
    const { profilePhoto, username, email, firstName, lastName, phoneNumber, emailVerified, accountActivated, brandName, websiteUrl, createdAt, updatedAt, id } = userData;
    const history = useHistory();    

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), /* -- */Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    const goToUserPage = () => {
        // console.log(userData);
        localStorage.setItem("UserPageData", JSON.stringify(userData));
        history.push(`/user/${id}`);
    }

    return (
        <tr className={`you-can-click`} onClick={goToUserPage}>
            <td>
                <img src={profilePhoto ? profilePhoto : "/assets/img/user.png"} alt="profile dp" style={{backgroundColor: "#ffffff"}} />
            </td>
            <td className="custom-text-capitalize">{username ?? "-"}</td>
            <td>{email ? email : "-"}</td>
            <td>{firstName ? firstName : "-"}</td>
            <td>{lastName ? lastName : "-"}</td>
            <td>{phoneNumber ? phoneNumber : "-"}</td>
            <td>
                {
                    emailVerified ? <span className="badge badge-outline-success">Verified</span> : <span className="badge badge-outline-warning">Not verified</span>
                }
            </td>
            <td>
                {
                    accountActivated ? <span className="badge badge-outline-success">Activated</span> : <span className="badge badge-outline-warning">Not activated</span>
                }
            </td>
            <td>{brandName ? brandName : "-"}</td>
            <td>{websiteUrl ? websiteUrl : "-"}</td>
            <td>{parseISOString(createdAt) ?? "-"}</td>
            <td>{parseISOString(updatedAt) ?? "-"}</td>
        </tr>
    )
}

export default UserTableRow
